const shareRs = () => {
    const iconShare = document.querySelectorAll('.sharing .share');
    if (iconShare != null) {
      Array.from(iconShare).forEach((share) => {
        share.addEventListener('click', (e) => {
          e.preventDefault();
          var id = share.id,
              url = share.getAttribute('data-url'),
              title = document.querySelector('meta[property=\'og:title\']').content || "Soprassistance",
              txt = document.querySelector('meta[property=\'og:description\']') != null ? document.querySelector('meta[property=\'og:description\']').content : "Soprassistance",
              thumb = document.querySelector('meta[property=\'og:image\']') != null ? document.querySelector('meta[property=\'og:image\']').content : '',
              w = 600,
              h = 450,
              l = (window.width - w) / 2,
              t = (window.height - h) / 2,
              i = i,
              s = "status=1,width=" + w + ",height=" + h + ",top=" + t + ",left=" + l,
              u = '',
              v,
              desc = '';

          if (id == "facebook") {
            u = 'http://www.facebook.com/sharer/sharer.php?url=' + encodeURIComponent(url);
          } else if (id == "twitter") {
            desc = title + ' ' + txt;
            u = "https://twitter.com/share?text=" + encodeURIComponent(desc) + "&url=https:" + encodeURIComponent(url);
          } else if (id == "linkedin") {
            u = 'https://www.linkedin.com/cws/share?url=' + encodeURIComponent(url);
          } else if (id == "googleplus") {
            u = "https://plus.google.com/share?url=" + url;
          } else if (id == "pinterest") {
            u = "https://pinterest.com/pin/find/?url=" + encodeURIComponent(url);
          } else if (id == "mail") {
            u = "mailto:?subject=Soprassistance&body=" + encodeURIComponent(url);
          }
          // console.log(url);
          window.open(u, id, s);
      });

    });
  }
}

shareRs();
