const getPositionSliderLeft = (el) => {
  if (el != null && window.matchMedia("(min-width: 1440px)").matches) {
    return parseInt(window.getComputedStyle(el,null).getPropertyValue('margin-left').replace('px', ''),10) + parseInt(window.getComputedStyle(el,null).getPropertyValue('padding-left').replace('px', ''), 10);
  }
  return 0;
};

const setPositionSliderLeft = (el, value) => {
    el.style.left = `${value}px`;
};



window.addEventListener("DOMContentLoaded", () => {
  const profilContainers = document.querySelectorAll('.flex-header-profil-col--slider');

  if (profilContainers != null || profilContainers != undefined) {

    let heroSlider = [];
    Array.from(profilContainers).forEach((profilContainer, i) => {
      profilContainer.classList.add('h'+i);
      const nbSlides = profilContainer.getAttribute('data-slide');
      if (nbSlides > 1) {
        heroSlider[i] = new Swiper('.h'+i+' .profil-slider-container', {
          loop: true,
          speed: 500,
          autoplay: {
            delay: 4000,
          },
          pagination: {
            el: '.h'+i+' .swiper-pagination',
            type: 'bullets',
          },
        });
      } else {
        heroSlider[i] = new Swiper('.h'+i+' .profil-slider-container');
      }

      // const heroNext = heroContainer.closest('.h'+i+' .swiper-container.flex-hero-slider-container .swiper-button-next');
      // const heroPrev = heroContainer.closest('.h'+i+' .swiper-container.flex-hero-slider-container .swiper-button-prev');
      // heroPrev.addEventListener('click', () =>{
      //   heroSlider[i].slidePrev();
      // });
      // heroNext.addEventListener('click', () =>{
      //   heroSlider[i].slideNext();
      // });
    });

    // setTimeout(function(){

    //   const allTitleSlider = document.querySelectorAll('.slider-desc.left');
    //   Array.from(allTitleSlider).forEach((titleSlider) => {
    //     setPositionSliderLeft(titleSlider, getPositionSliderLeft(headerWrapper));
    //   });

    //   // deal with the page getting resized or scrolled
    //   window.addEventListener("resize", () => {
    //     Array.from(allTitleSlider).forEach((titleSlider) => {
    //       setPositionSliderLeft(titleSlider, getPositionSliderLeft(headerWrapper));
    //     });
    //   }, false);
    // }, 250);
  }
});
