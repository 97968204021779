const getPositionContainerLeft = (el) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    return parseInt(window.getComputedStyle(el,null).getPropertyValue('margin-left').replace('px', ''),10);
  }
};

const setPositionPaddingLeft = (el, value) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    el.style.paddingLeft = `${value}px`;
  } else {
    el.style.paddingLeft = '2rem';
  }
};

const setCarouselLeft = (el, value) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    el.style.marginLeft = `${value}px`;
    el.style.width = `calc(100% - ${value}px)`;
  } else {
    el.style.marginLeft = '1rem';
    el.style.width = 'auto';
  }
};



window.addEventListener("DOMContentLoaded", () => {
  const carouselContainers = document.querySelectorAll('.flex-carousel');

  if (carouselContainers != null || carouselContainers != undefined) {

    let heroSlider = [];

    let container = null;
    const customContainer = document.querySelector('.custom-container');
    const customContainerSmall = document.querySelector('.custom-container-small');
    const headerProfil = document.querySelector('.flex-header-profil .flex-header-profil-col--blue')
    const carouselContainer = document.querySelector('.flex-carousel')

    container = customContainer !== null ? customContainer : customContainerSmall;
    // setTimeout(function(){


    if (container !== null && headerProfil) {
      setPositionPaddingLeft(headerProfil, getPositionContainerLeft(container));
        window.addEventListener("resize", () => {
          setPositionPaddingLeft(headerProfil, getPositionContainerLeft(container));
        });
    }

    if (container !== null && carouselContainer) {
      setCarouselLeft(carouselContainer, getPositionContainerLeft(container));
        window.addEventListener("resize", () => {

          setCarouselLeft(carouselContainer, getPositionContainerLeft(container));
        });
    }
    // }, 250);

    Array.from(carouselContainers).forEach((carouselContainer, i) => {
      carouselContainer.classList.add('h'+i);
      const nbSlides = carouselContainer.getAttribute('data-slide');
      if (nbSlides > 1) {
        heroSlider[i] = new Swiper('.h'+i+' .flex-carousel-container', {
          loop: false,
          speed: 500,
          spaceBetween: 30,
          navigation: {
            nextEl: '.h'+i+' .swiper-button-next',
            prevEl: '.h'+i+' .swiper-button-prev',
          },
          breakpoints: {
            320: {
              slidesPerView: 1.3,
            },
            768: {
              slidesPerView: 2.3,
            },
            992: {
              slidesPerView: 3,
            }
          }
        });
      } else {
        heroSlider[i] = new Swiper('.h'+i+' .flex-slider-container');
      }

    });

  }
});
