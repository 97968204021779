const setPositionPagination = (pagination, legend) => {
  if (pagination !== null && legend !== null) {
    const hLegend = legend.clientHeight;
    const mTopLegend = parseInt(window.getComputedStyle(legend,null).getPropertyValue('margin-top').replace('px', ''), 10);

    const newH = (hLegend/2) - mTopLegend + 15; //15 = moitier de la hauteur des fleches
    pagination.style.top = `calc(50% - ${newH}px)`;
  }
}

window.addEventListener("DOMContentLoaded", () => {
  const abSliderContainer = document.querySelector('.flex-slider-ab');
  const abPagination = document.querySelector('.flex-slider-ab .custom-pagination');
  const abLegend = document.querySelector('.flex-slider-ab .flex-slider-ab--slider--legend');

  if (abSliderContainer != null) {
    const nbSlides = abSliderContainer.getAttribute('data-slide');
    if (nbSlides > 1) {
      const abSlider = new Swiper('.flex-slider-ab--slider', {
        loop: true,
        // loopedSlides: nbSlides * 2,
        // slidesPerView: 1.3,
        speed: 500,
        autoplay: {
          delay: 3000,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
          },
          // when window width is >= 480px
          769: {
            slidesPerView: 1.3,
          },
        },
        navigation: {
          nextEl: '.flex-slider-ab .swiper-button-next',
          prevEl: '.flex-slider-ab .swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return ('0' + current).slice(-2) + ' / <span class="total">' + ('0' + total).slice(-2)+'</span>';
          }
        },
      });
    }
  }

  setPositionPagination(abPagination, abLegend);

  // deal with the page getting resized or scrolled
  window.addEventListener("resize", () => {
    setPositionPagination(abPagination, abLegend);
  }, false);

});
