const getPositionLeft = (el) => {
  if (el != null) {
    return window.getComputedStyle(el,null).getPropertyValue('margin-left');
  }
};

const setPositionLeft = (el, value) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    el.style.paddingLeft = value;
  } else if ( el !=null && window.matchMedia("(min-width: 768px) and (max-width: 992px)").matches) {
    el.style.paddingLeft = '18.2%';
  } else {
    el.style.paddingLeft = '1rem';
  }
};

const updatePositionLeft = (el, value) => {
  // add your code to update the position when your browser
  // is resized or scrolled
  setPositionLeft(el, value);
};
window.addEventListener("DOMContentLoaded", () => {
  const testimonialContainers = document.querySelectorAll('.flex-testimonials');

  if (testimonialContainers != null || testimonialContainers != undefined) {
    Array.from(testimonialContainers).forEach((testimonialContainer, i) => {
      testimonialContainer.classList.add('s'+i);
      new Swiper('.s'+i+' .flex-testimonials-slider-container', {
        loop: false,
        speed: 400,
        navigation: {
          nextEl: '.s'+i+' .swiper-button-next',
          prevEl: '.s'+i+' .swiper-button-prev',
        },
        spaceBetween: 48,
        slidesPerView: 'auto',
      });
      const blocTestimonials = document.querySelector('.flex-testimonials');
      const containerFooter = document.querySelector('.footer .custom-container');
      setPositionLeft(blocTestimonials, getPositionLeft(containerFooter));

      // deal with the page getting resized or scrolled
      window.addEventListener("resize", () => {
        updatePositionLeft(blocTestimonials, getPositionLeft(containerFooter));
      });
    });
  }
});
