const removePlaceholder = (input) => {
  if (input != null) {
    const inputSearch = input;
    const placeholder = inputSearch.placeholder;
    const submitSearch = document.querySelector('.header .search-bloc button');
      submitSearch.disabled = 'disabled';
      inputSearch.focus();
      inputSearch.removeAttribute('placeholder');

      if (inputSearch.value !== '') {
        submitSearch.removeAttribute('disabled');
      }
      inputSearch.addEventListener('keyup', (e) => {
        if (e.target.value !== '') {
          submitSearch.removeAttribute('disabled');
          inputSearch.setAttribute('placeholder', placeholder);
        } else {
          submitSearch.disabled = 'disabled';
        }
    });
  }
}

const btnSearch = document.querySelector('.header .search-bar-btn');
const searchBloc = document.querySelector('.header .search-bloc');

const inputSearchAside = document.querySelector('.menu-aside .search-form input');
const submitSearchAside = document.querySelector('.menu-aside .search-form button');

const listenSearchHeader = (btnSearch, searchBloc) => {
  if (btnSearch != null && searchBloc != null) {
    const submitSearch = document.querySelector('.header .search-bloc button');
    const formSearch = document.querySelector('.header .search-form');
    const menuProfil = document.querySelector('.header .menu-profil');
    const menuAgency = document.querySelector('.header .menu-agency');
    formSearch.style.width = menuProfil.offsetWidth + menuAgency.offsetWidth + submitSearch.offsetWidth + 48 + 'px';

    window.addEventListener("resize", () => {
      formSearch.style.width = menuProfil.offsetWidth + menuAgency.offsetWidth + submitSearch.offsetWidth + 48 + 'px';
    });

    btnSearch.addEventListener('click', () => {
      searchBloc.classList.add('open');
      const inputSearch = document.querySelector('.header .search-bloc input');
      removePlaceholder(inputSearch);
    });

    document.body.addEventListener('click', function(evt) {
      if (evt.target.closest('.header .search-bloc') == null) {
        searchBloc.classList.remove('open');
      }
    });
  }
}

const listenSearchAside = (inputSearchAside, submitSearchAside) => {
  if (inputSearchAside != null && submitSearchAside != null) {
    removePlaceholder(inputSearchAside);
  }
}

listenSearchHeader(btnSearch, searchBloc);
listenSearchAside(inputSearchAside,submitSearchAside);
