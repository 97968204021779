const backTop = () => {
  const backTopElem = document.querySelector('.back-top');
  if (backTopElem) {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 720) {
        backTopElem.classList.add('back-top--visible');
      } else {
        backTopElem.classList.remove('back-top--visible');
      }
    })
    backTopElem.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  }
}
backTop();
