const agenceMailInput = document.querySelector('.so_agence_mail input');
const cpInput = document.querySelector('.so_cp input');

var selectShow = document.querySelector('.select-show select option:first-child');
var formHide = document.querySelector('.form-to-hide');
if (selectShow !== null && formHide !== null) {

	document.querySelector('.select-show select').addEventListener('change', function(e){
		if (e.target.value !== "Sélectionnez") {
			formHide.style.display = "inline-block";
		}
		else {
			formHide.style.display = "none";
		}
	});
}

const getEmailByDep = (cp) => {
  if (cp !== '' && cp.length > 2) {
    const newVal = cp.substr(0,2);
    const action = `action=get_email_by_dep&dep=${newVal}`;

    const queryListParams = {
      method: 'post',
      url: ajax_url,
      data: action
    };

    axios(queryListParams)
      .then(function (response) {
        // handle success
        if (response.data !== '') {
          console.log(response.data);
          agenceMailInput.value = response.data;
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
    }
}

if (agenceMailInput !== null && cpInput !== null) {
    getEmailByDep(cpInput.value);
  cpInput.addEventListener('blur', function(e){
    getEmailByDep(e.target.value);
  })
}
