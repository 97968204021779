var apiGouvUrl = 'https://api-adresse.data.gouv.fr/search/';
var maxSuggestions = 10;

function autocomplete(inp) {

  function setValue(label, lat, lng) {
    // inp.setAttribute('value', label);
    inp.dataset.bestValue = label;
    inp.dataset.bestValueLat = lat;
    inp.dataset.bestValueLng = lng;


    localStorage.setItem('agencySearch', JSON.stringify({
      bvalue: label,
      bvalueLat: lat,
      bvalueLng: lng,
    }));
  }

  function geoAutocomplete(a, inp, val, maxSuggestions) {
    var queryParams = {
      method: 'get',
      url: apiGouvUrl,
      params: {
        q: val,
        limit: maxSuggestions,
        type: 'municipality',
        autocomplete: 1
      }
    };

    axios(queryParams)
      .then(function (response) {
        // handle success
        var arr = response.data.features;

        arr.forEach(function(feature) {
          // if doesnt work on postal code value
          // if (feature.properties.label.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          var b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = "<strong>" + feature.properties.label.substr(0, val.length) + "</strong>";
          b.innerHTML += feature.properties.label.substr(val.length);
          b.innerHTML += "<input type='hidden' value='" + feature.properties.label + "' data-best-value-lat='" + feature.geometry.coordinates[1] + "' data-best-value-lng='" + feature.geometry.coordinates[0] + "'>";


          // setValue(feature.properties.label, feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

          /*execute a function when someone clicks on the item value (DIV element):*/
            b.addEventListener("click", function(e) {


              /*insert the value for the autocomplete text field:*/
              // inp.setAttribute('value', this.getElementsByTagName("input")[0].value);

              // inp.setAttribute('value', this.getElementsByTagName("input")[0].value);
              inp.value = this.getElementsByTagName("input")[0].value;


              setValue(this.getElementsByTagName("input")[0].value, this.getElementsByTagName("input")[0].dataset.bestValueLat, this.getElementsByTagName("input")[0].dataset.bestValueLng);
              // console.log(this.getElementsByTagName("input")[0].value);

              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
            });
            a.appendChild(b);
            if (a.offsetHeight >= 200) {
              a.classList.add('overflowed');
            }
          // }
        });
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    });
  }
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", function(e) {
      var a, val = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");

      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*for each item in the array...*/

      if (e.target.value.length > 2) {
        geoAutocomplete(a, inp, val, maxSuggestions);
      }
  });
  /*execute a function presses a key on the keyboard:*/
  inp.addEventListener("keydown", function(e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) {
            x[currentFocus].click();
          }
        }
      }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (x.length - 1);
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
    setValue(x[currentFocus].getElementsByTagName("input")[0].value, x[currentFocus].getElementsByTagName("input")[0].dataset.bestValueLat, x[currentFocus].getElementsByTagName("input")[0].dataset.bestValueLng);

  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  /*execute a function when someone clicks in the document:*/
  document.addEventListener("click", function (e) {
      closeAllLists(e.target);
  });
}
