if (navigator.userAgent.indexOf('MSIE') !== -1
|| navigator.appVersion.indexOf('Trident/') > -1) {
  /* Microsoft Internet Explorer detected in. */
  // eslint-disable-next-line
  console.log('Advisa - Made with ❤ & ☕');
} else {
  const style = [
    'background: #000',
    'color: #fff',
    'padding: 10px 16px',
    'line-height: 26px',
  ].join(';');

  // eslint-disable-next-line no-console
  console.log('%c Advisa - Made with ❤ & ☕', style);
}
