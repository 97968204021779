const header = document.querySelector('.header');
const headerWrapper = document.querySelector('.header-wrapper');
const headerWrapperNav = document.querySelector('.menu-aside');
const menuClose = document.querySelector('.burger-close');
const menuBars = document.querySelector('.burger-bars');

let taskTimeoutID;

const getPositionRight = (el) => {
  if (el) {
    return window.getComputedStyle(el,null).getPropertyValue('margin-right');
  }
};

const getPositionLeft = (el) => {
  if (el) {
    return window.getComputedStyle(el,null).getPropertyValue('margin-left');
  }
};

const getPositionFullRight = (el, wrapper) => {
  if (el && wrapper) {
    let right = parseInt(window.getComputedStyle(wrapper,null).getPropertyValue('margin-right').replace('px', ''),10) + parseInt(window.getComputedStyle(wrapper,null).getPropertyValue('padding-right').replace('px', ''), 10);

    if ((window.matchMedia("(max-width: 768px)").matches)) {
      return '30px';
    } else if ((window.matchMedia("(min-width: 768px)").matches && window.matchMedia("(max-width: 992px)").matches)) {
      return `${document.body.clientWidth - el.offsetLeft - el.offsetWidth}px`;
    } else {
      return `${right}px`;
    }
  }
};

const setPosition = (el, value) => {
  if (el && window.matchMedia("(min-width: 768px)").matches) {
    el.style.paddingRight = value;
  } else {
    el.style.paddingRight = '1rem';
  }
};

const setPositionLeft = (el, value) => {
  if (el && window.matchMedia("(min-width: 768px)").matches) {
    el.style.paddingLeft = value;
  } else {
    el.style.paddingLeft = '1rem';
  }
};

const setPositionBurger = (el, toPos, wrapper) => {
  toPos.style.right = getPositionFullRight(el, wrapper);
};


const getHeight = (el) => {
  if (el) {
    return window.getComputedStyle(el,null).getPropertyValue('height');
  }
};

const getHeightTransform = (el) => {
  if (el) {
    const top = parseInt(window.getComputedStyle(el,null).getPropertyValue('height').replace('px', ''),10) - 130;
    return `${top}px`;
  }
};

const openNav = () => {
  const headerW = document.querySelector('.header-wrapper');
  const menuAside = document.querySelector('.menu-aside');

  menuBars.addEventListener('click', () => {
    headerW.classList.add('is-open');
    menuAside.classList.add('is-open');
    document.body.style.overflowY = 'hidden';
  });

  menuClose.addEventListener('click', () => {
    headerW.classList.remove('is-open');
    menuAside.classList.remove('is-open');
    document.body.style.overflowY = 'scroll';
  });
}

const stickyHeader = () => {
  const header = document.querySelector('.header');
  if (header != null) {
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 134) {
        header.classList.add('sticky');
        menuClose.classList.add('burger-close--sticky');
        headerWrapperNav.classList.add('menu-aside--sticky');
      }
      else {
        menuClose.classList.remove('burger-close--sticky');
        headerWrapperNav.classList.remove('menu-aside--sticky');
        header.classList.remove('sticky');
      }
    });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  stickyHeader();
  openNav();
  setPositionBurger(menuBars, menuClose, headerWrapper);

  // deal with the page getting resized or scrolled
  window.addEventListener("resize", () => {
    setTimeout(function() {
      setPositionBurger(menuBars, menuClose, headerWrapper);
    }, 100);
  }, false);

  window.addEventListener("scroll", () => {
      setPositionBurger(menuBars, menuClose, headerWrapper);
  }, false);
});
