const getPositionContainerLeft = (el) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    return parseInt(window.getComputedStyle(el,null).getPropertyValue('margin-left').replace('px', ''),10);
  }
};

const setPositionPaddingLeft = (el, value) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    el.style.paddingLeft = `${value}px`;
  } else {
    el.style.paddingLeft = '2rem';
  }
};

const setCarouselLeft = (el, value) => {
  if (el != null && window.matchMedia("(min-width: 992px)").matches) {
    el.style.marginLeft = `${value}px`;
    el.style.width = `calc(100% - ${value}px)`;
  } else {
    el.style.marginLeft = '1rem';
    el.style.width = 'auto';
  }
};

window.addEventListener("DOMContentLoaded", () => {
  const blocPrestations = document.querySelectorAll('.flex-header-prestation');

  if (blocPrestations != null) {

    let heroSlider = [];

    let container = null;
    const customContainer = document.querySelector('.custom-container');
    const customContainerSmall = document.querySelector('.custom-container-small');
    const headerPrestation = document.querySelector('.flex-header-prestation .flex-header-prestation-col--blue')

    container = customContainer !== null ? customContainer : customContainerSmall;

    if (container !== null && headerPrestation) {
      setPositionPaddingLeft(headerPrestation, getPositionContainerLeft(container));
        window.addEventListener("resize", () => {
          setPositionPaddingLeft(headerPrestation, getPositionContainerLeft(container));
        });
    }
  }
});
